input {
	&:not([type='submit']) {
		&:not([type='checkbox']) {
			&:not([type='reset']) {
				color: $gray-700;
			}
		}
	}
	&:not([type='checkbox']) {
		&:not([type='submit']) {
			&:not([type='reset']) {
				&:not(.form-control:disabled) {
					&:not(.form-control[readonly]) {
						&:not(.no-border) {
							border: 1px solid $feild_border !important;
						}
						color: $gray-700 !important;
						background-color: $white !important;
						-webkit-box-shadow: none;
						box-shadow: none;
						-webkit-transition: border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
						transition: border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
						-o-transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
						transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
						transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
						height: calc(1.5em + .625rem + 2px) !important;
						&::-webkit-input-placeholder {
							color: $gray-500 !important;
						}
						&::-moz-placeholder {
							color: $gray-500 !important;
						}
						&:-ms-input-placeholder {
							color: $gray-500 !important;
						}
						&::-ms-input-placeholder {
							color: $gray-500 !important;
						}
						&::placeholder {
							color: $gray-500 !important;
						}
					}
				}
			}
		}
	}
	&.form-control:disabled {
		cursor: not-allowed;
		background-color: $input-disabled-bg !important;
	}
	&.form-control[readonly] {
		cursor: not-allowed;
		background-color: $input-disabled-bg !important;
	}
}
.form-group input.form-control.requiredField {
    border-left: 1px solid red !important;
}
h1 {
	color: $gray-700;
}
h2 {
	color: $gray-700;
}
h3 {
	color: $gray-700;
}
h4 {
	color: $gray-700;
}
h5 {
	color: $gray-700;
}
h6 {
	color: $gray-700;
}
label {
	color: $gray-700;
}
th {
	color: $gray-700;
}
td {
	color: $gray-700;
}
a {
	color: $gray-700;
}
.theme-revamp-table.table {
	thead {
		th {
			color: $gray-700;
		}
	}
}
select {
	color: $gray-700 !important;
	background-color: $white !important;
	border: 1px solid $feild_border !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
	transition: border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
	-o-transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
	transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
	transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
	height: calc(2.09375rem + 4px);
}
select:disabled.form-control, select[readonly].form-control, .select2-container--disabled > .selection > .select2-selection {
	background-color: $input-disabled-bg !important;
	cursor: not-allowed;
}
.select2-selection--single {
	.select2-selection__rendered {
		line-height: calc(2.09375rem + 3px) !important;
	}
	.select2-selection__arrow {
		height: calc(2.09375rem + 3px) !important;
	}
}
.select2-container--default {
	.select2-selection--single {
		border: 1px solid $feild_border !important;
		height: calc(2.09375rem + 3px) !important;
		background-color: $white !important;
		.select2-selection__rendered {
			color: $gray-700 !important;
		}
		.select2-selection__placeholder {
			color: $gray-500 !important;
		}
	}
	.select2-search--dropdown {
		.select2-search__field {
			border: 1px solid $feild_border !important;
			background-color: $white !important;
		}
	}
	.select2-results__option[aria-selected=true] {
		background-color: $gray-300;
	}
	.select2-selection--multiple {
		background-color: $white !important;
	}
}
.input-group {
    .select2-container--default {
        .select2-selection--single {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }
}
.select2-dropdown {
	border: 1px solid $feild_border !important;
	background-color: $white !important;
}
input.form-control {
	color: $gray-700 !important;
}
.input-group.select2-prepend {
	position: relative;
	display: table;
	border-collapse: separate;
	.input-group-prepend {
		display: table-cell;
		width: 1%;
		white-space: nowrap;
		vertical-align: middle;
		span.input-group-text.pointer.lookup-modal-toggle {
			display: block;
			background-clip: padding-box;
			padding: 0.4375rem 0.75rem;
			font-size: 0.8125rem;
			line-height: 1.5;
			color: $gray-700 !important;
			background-color: $white !important;
			border: 1px solid $feild_border;
			font-weight: 300;
			will-change: border-color, box-shadow;
			border-radius: 0.25rem;
			-webkit-box-shadow: none;
			box-shadow: none;
			-webkit-transition: border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
			transition: border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
			-o-transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
			transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
			transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
			height: calc(2.09375rem + 3px);
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			cursor: pointer;
		}
	}
}
span.select2.select2-container.select2-container--default {
	width: 100% !important;
}
.form-control {
	&::-webkit-input-placeholder {
		color: $gray-500 !important;
		opacity: 1;
	}
	&::-moz-placeholder {
		color: $gray-500 !important;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: $gray-500 !important;
		opacity: 1;
		color: $gray-500 !important;
	}
	&::-ms-input-placeholder {
		color: $gray-500 !important;
		opacity: 1;
		color: $gray-500 !important;
	}
	&::placeholder {
		color: $gray-500 !important;
		opacity: 1;
	}
}

#vvveb-builder {
	input:not([type=checkbox]):not([type=submit]):not([type=reset]), select {
		border: 1px  solid #e1e5eb !important;
		background-color: #fff !important;
	}
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    background-position: right calc(0.375em + 0.625rem) center !important;
}
.top_buttons {
	display: none;
}
