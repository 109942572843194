$check-bg: #33475b;
$lightgray: #9c9e9f;
$gray: #bdc1c6;

* {
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}



// dataTable Styling



.dataTables_wrapper {
    table.dataTable thead th, table.dataTable thead td {
        border-bottom: 0 !important;
    }
    .dataTables_scrollHeadInner,.table.dataTable.no-footer {
        width: 100% !important;
    }
    .dataTables_paginate {
        padding: 0.25em 0;
        .paginate_button {
            border-radius: 4px;
            min-width: 2.9em !important;
            -webkit-transition: all .2s linear;
            -o-transition: all .2s linear;
            transition: all .2s linear;
            &:hover {
                -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
            }
        }
         .paginate_button.current {
            -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        }
    }
    #list-table_last, #list-table_first {
        padding: 0.4rem .7rem;
    }
    
    table.dataTable thead th.sorting, table.dataTable thead th.sorting_asc, table.dataTable thead th.sorting_desc {
        min-width: 200px !important;
        font-weight: 600;
    }
    table {
        clear: both;
        margin-bottom: 6px !important;
        max-width: none !important;
        table-layout: fixed;
        word-break: break-all;
        .thead-light th {
            background: transparent;
            min-width: 40px;
        }
       } 
    table.dataTable {
        display: block !important;
        &.d_table {
            display: table !important;
        }
    }
    .dataTables_length select {
        max-width: 50px;
        line-height: 41px;
        padding: 0;
    }
    .dataTables_length > label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    table.dataTable > thead .sorting:before, table.dataTable > thead .sorting_asc:before, table.dataTable > thead .sorting_desc:before, table.dataTable > thead .sorting_asc_disabled:before, table.dataTable > thead .sorting_desc_disabled:before {
        content: '' !important;
    }
    table td:not(:nth-child(1)):not(:nth-child(2)), table td a {
        max-width: 120px;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        word-break: break-all;
        overflow: hidden;
        text-align: left;
        // padding-left: 5px;
        }
        
           &> .dt-buttons {
            padding: 10px;
                .dt-button-collection {
                    button.dt-button.button-page-length.active {
                        -webkit-box-shadow: none !important;
                                box-shadow: none !important;
                        border: 0 ;
                        background: #ff7a59 !important;
                        color: #fff !important;
                    }
                    button.dt-button.button-page-length {
                        -webkit-box-shadow: none !important;
                                box-shadow: none !important;
                        background: transparent !important;
                        &:hover {
                            background-color: #ff7a59 !important;
                            border-color: #ff7a59 !important;
                            color: #fff !important;
                        }
                    }
                }
                &>  .dt-button {
                    background: #ff7a59;
                    color: #fff !important;
                    border-color: #ff7a59;
                    background-image: none !important;
                    padding: 5px 1rem;
                    &:hover {
                        background-color: #ff7a59a1;
                        border-color: #ff7a59;
                        color: #fff !important;
                    }
                }
           }
           &> .dataTables_filter {
                padding: 10px;
           }
           &> .dataTables_paginate {
               .paginate_button.current {
                    background: #ff7a59 !important;
                    color: #fff !important;
                    border-color: #ff7a59 !important;
                    &:hover {
                        background: #ff7a59 !important;
                        color: #fff !important;
                    }
               }
           }
    
           button.dt-button:active:not(.disabled):hover:not(.disabled),
           button.dt-button.active:not(.disabled):hover:not(.disabled),
           div.dt-button:active:not(.disabled):hover:not(.disabled),
           div.dt-button.active:not(.disabled):hover:not(.disabled),
           a.dt-button:active:not(.disabled):hover:not(.disabled),
           a.dt-button.active:not(.disabled):hover:not(.disabled) {
            -webkit-box-shadow: none;
                    box-shadow: none;
            background: #ff7a59;
           }
           .paginate_button {
            &.next{
                padding: .6rem .9rem;
            }
            &.previous{
                padding: .6rem .9rem;
            }
            &.last {
                padding: .6rem .7rem;
            }
            &.first {
                padding: .6rem .7rem;
            }
        }
        
        .table-bordered-custom_design {
            border: 0 !important;
                tr {
                    &:hover td{
                        background-color: $table_hover_clr !important;;
                    }
                    border: 0;
                    th {
                        border: 0 !important;
                        border-bottom: solid 1px $gray-300 !important;
                        background-color: transparent !important;
                    }
                    td{
                        border: 0 !important;
                        border-bottom: solid 1px $gray-300 !important;
                        background-color: transparent !important;
                    }
                }
            &.table-striped tbody tr:nth-of-type(odd) {
                background-color: transparent;
            }
        }
}

// .dataTables_wrapper table.dataTable thead th {
//     padding: 0.75rem 1.7rem;
// }
.dataTables_wrapper table.dataTable thead th {
    text-align: left !important;
}
table.dataTable tbody th, table.dataTable tbody td, table.dataTable thead th, table.dataTable thead td {
    padding: 5px 5px 5px 5px!important;
}
table[id*="DataTables_Table_"] {
    & > thead{
        display: none;
        th {
            padding: 0 !important;
        }
    }
}
.dataTables_wrapper table.dataTable thead th[class*='sorting']{
    text-align: left !important;
}

table.dataTable tbody tr {
    background-color: transparent !important;
}


.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate, .dataTables_wrapper .dataTables_paginate .paginate_button,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active
{
    color: $gray-700 !important;
}

.x_panel {
    position: relative;
    width: 100%;
    margin: 10px 0;
    padding: 10px 17px;
    display: inline-block;
    background: $white;
    border: 1px solid $gray-300;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    -o-transition: all .2s ease;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    overflow: hidden;
  }
  .clearfix:after, form:after:not(.modal-body form:after) {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  .x_content {
    padding: 0 3px 6px;
    position: relative;
    width: 100%;
    float: left;
    clear: both;
    margin-top: 5px;
  }
  .x_content h4 {
    font-size: 16px;
    font-weight: 500;
  }
  legend {
    padding-bottom: 7px;
  }

div.table-responsive>div.dataTables_wrapper>div.row {
    margin: 0;
}
div.dataTables_wrapper div.dataTables_length label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length select {
    width: 75px;
    display: inline-block;
}
.dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 30%;
    left: 50%;
    width: 30%;
    height: 40px;
    margin-left: -20%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background:none;
    }

.dataTables_paginate .paginate_button.current, .dataTables_paginate .paginate_button:not(.disabled):hover {
    background: #555;
    color: #fff;
    border-color: #555;
}
.sub-drop-icon {
    min-width: 20px;
    position: absolute;
    cursor: pointer;
}
.detail-widgets-c {
    .dataTables_wrapper {
        .dataTables_scrollHeadInner {
            table.dataTable {
                display: table !important;
            }
        }
        -webkit-box-shadow: none !important;
                box-shadow: none !important;
    }
    .dataTables_scrollBody {
        table[id*="relatedListTable"] thead.thead-light {
            display: table-footer-group;
            opacity: 0;
            visibility: collapse;
        }
    }
}

.classic-loader-list {
    position: absolute;top: 0;width: 100%;right: 0;height: 100%;z-index: 999;overflow: hidden;
}
.classic-loader-list-inner {
    position: absolute;top: 110px;width: 100%;right: 0px;height: 70%;z-index: 999;overflow: hidden;height: calc(100% - 158px);top: 109px;display: none;
    &[class*="classic-loader-list-inner-"] {
        height: calc(100% - 11.2em);
        top: 96px;
    }
}

ul[id*='toggle-menu-'] {
    position: absolute;
    z-index: 999;
    background: #fff;
    top: 80px;
    left: 54px;
    padding: 0;
    border-radius: 0;
    li {
        padding: 0;
        &:hover {
            background: transparent;
        }
    }
}
.dataTables_filter input {
    outline: 0;
    font-size: .75rem;
    line-height: 1.5;
    color: $dark;
    background-color: #fff;
    border: 1px solid $gray-300;
    font-weight: 300;
    border-radius: .25rem;
    margin-left: .3125rem;
    padding: .4375rem .625rem .4375rem 1.75rem;
    min-width: 11.25rem;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjQ0FDRURCIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjUgMTRoLS43OWwtLjI4LS4yN0E2LjQ3MSA2LjQ3MSAwIDAwMTYgOS41IDYuNSA2LjUgMCAxMDkuNSAxNmMxLjYxIDAgMy4wOS0uNTkgNC4yMy0xLjU3bC4yNy4yOHYuNzlsNSA0Ljk5TDIwLjQ5IDE5bC00Ljk5LTV6bS02IDBDNy4wMSAxNCA1IDExLjk5IDUgOS41UzcuMDEgNSA5LjUgNSAxNCA3LjAxIDE0IDkuNSAxMS45OSAxNCA5LjUgMTR6Ii8+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
    background-position: left 7px center;
    background-repeat: no-repeat;
    background-size: 10%;
    will-change: border-color,box-shadow;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transition: border .25s cubic-bezier(.27,.01,.38,1.06),-webkit-box-shadow .25s cubic-bezier(.27,.01,.38,1.06);
    transition: border .25s cubic-bezier(.27,.01,.38,1.06),-webkit-box-shadow .25s cubic-bezier(.27,.01,.38,1.06);
    -o-transition: box-shadow .25s cubic-bezier(.27,.01,.38,1.06),border .25s cubic-bezier(.27,.01,.38,1.06);
    transition: box-shadow .25s cubic-bezier(.27,.01,.38,1.06),border .25s cubic-bezier(.27,.01,.38,1.06);
    transition: box-shadow .25s cubic-bezier(.27,.01,.38,1.06),border .25s cubic-bezier(.27,.01,.38,1.06),-webkit-box-shadow .25s cubic-bezier(.27,.01,.38,1.06);
}
.dataTables_filter input:focus {
    border-color: #ff7a53 !important;
    border: 1px solid;
    border-radius: 5px !important;
    -webkit-box-shadow: 0 0.313rem 0.719rem rgba(253, 123, 3, 0.15), 0 0.156rem 0.125rem rgba(252, 122, 2, 0.15) !important;
            box-shadow: 0 0.313rem 0.719rem rgba(253, 123, 3, 0.15), 0 0.156rem 0.125rem rgba(252, 122, 2, 0.15) !important;
}
.dataTables_length select {
    border: 1px solid #e1e5eb;
    font-size: .625rem;
    color: $dark;
    height: 1.5625rem;
    background-color: $white;
    border-radius: .375rem;
    margin: 0 .1875rem;
}
div.dataTables_wrapper div.dataTables_info {
    padding-top: 1.25em !important;
}
div.dataTables_wrapper div.dataTables_paginate {
    padding-top: 0.65em;
}
@media only screen and (max-width: 800px) {
    #view_list td {
        max-width: 100%;
    }
    #view_list {
        .dataTables_wrapper table.dataTable.dtr-inline.collapsed tbody > tr[role=row] > td:first-child:before, .dataTables_wrapper table.dataTable.dtr-inline.collapsed tbody > tr[role=row] > th:first-child:before {
            text-align: center;
            width: 1.25rem;
            height: 1.25rem;
            line-height: 1.25rem;
            font-size: 1rem;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            font-weight: 700;
            -webkit-box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
                    box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
            content: '+' !important;
            padding: 0;
            background-color: #ff7a59;
        }
        div.dataTables_wrapper div.dataTables_length label {
            text-align: center;
            display: block;
        }
        div.dataTables_wrapper div.dataTables_filter label {
            text-align: center;
        }
    }
        
    .detail-widgets-c {
        .dataTables_wrapper table.dataTable.dtr-inline.collapsed tbody > tr[role=row] > td:first-child:before, .dataTables_wrapper table.dataTable.dtr-inline.collapsed tbody > tr[role=row] > th:first-child:before {
            text-align: center;
            width: 1.25rem;
            height: 1.25rem;
            line-height: 1.25rem;
            font-size: 1rem;
            left: 60%;
            top: 60%;
            -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            font-weight: 700;
            -webkit-box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
                    box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
            content: '+' !important;
            padding: 0;
            background-color: #ff7a59;
        }
        .dataTables_wrapper table.dataTable.dtr-inline.collapsed tbody > tr[role=row] > td:first-child {
            width: 100%;
        }
        div.dataTables_wrapper div.dataTables_length label {
            text-align: center;
            display: block;
        }
        div.dataTables_wrapper div.dataTables_filter label {
            text-align: center;
        }
    }
}


div.dt-button-collection {
    background-color: $white;
}
button.dt-button, div.dt-button, a.dt-button {
    color: $dark;
}
