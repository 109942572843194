.report-section {
	.nav {
		>li {
			>a {
				padding: 0;
				border-bottom: 1px dashed $gray-300;
				span {
					display: flex;
					align-items: center;
					svg.icons {
						width: 10px;
						height: 10px;
					}
					svg {
						width: 10px;
						height: 10px;
					}
				}
			}
		}
	}
}
.customize-reports {
	background: transparent !important;
}
.overflow-auto {
	overflow: auto !important;
}
.column {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	background: $white;
	z-index: 9;
	-webkit-animation: fadein .5s;
	animation: fadein .5s;
}
.rep-new-tab {
	position: relative;
	z-index: 4;
}
.close-details {
	float: right;
	padding-top: 8px;
	margin-left: 25px;
	cursor: pointer;
	color: #777;
}
svg.icons {
	width: 14px;
	height: 14px;
	margin-right: 6px;
	fill: currentcolor;
}
.criteria-wrapper.show {
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
}
.criteria-wrapper {
	background: $white;
	position: relative;
	z-index: 3;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: -webkit-transform .3s ease-out;
	transition: -webkit-transform .3s ease-out;
	-o-transition: transform .3s ease-out;
	transition: transform .3s ease-out;
	transition: transform .3s ease-out, -webkit-transform .3s ease-out;
}
.criteria-row {
	background-color: $white;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0;
	.criteria-content {
		background-color: $white;
		width: 100% !important;
		padding: 15px;
		border-left: 1px solid $gray-300;
		border-right: 1px solid $gray-300;
	}
}
.col-form-label {
	padding-top: calc(.375rem + 1px);
	padding-bottom: calc(.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
	float: none;
	margin-top: 5px;
	padding-top: inherit;
	text-align: inherit;
	width: auto;
}
.criteria-actions {
	padding: 15px;
	border: 1px solid $gray-300;
	background: $white;
}

.mLbl {
	color: #b94a48;
	&:after {
		content: "*";
	}
}

.contom-view_x_arrows,.contom-view_y_arrows {
	display: flex;
	flex-direction: column;
}


.report-main-container {
    display: grid;
    grid-template-areas:
        "sidebar classic-table-content";
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    width: 100%;
    height: 100%;
    overflow: hidden;
	.sidebar {
		grid-area: sidebar;
	}

	.sidebar {
		width: 300px;
		z-index: 1;
	}
	.sidebar {
		position: relative;
		transition: width .2s cubic-bezier(.645,.045,.355,1)
	}

	.sidebar .sidebar-content,.sidebar .sidebar-filter,.sidebar .sidebar-header,.sidebar .sidebar-submenu {
		transition: opacity .2s cubic-bezier(.645,.045,.355,1),visibility .2s cubic-bezier(.645,.045,.355,1)
	}

	.sidebar.collapsing,.sidebar.is-collapsed, .sidebar.is-collapsed .sidebar-wrapper{
		width: 0
	}

	.sidebar.collapsing .sidebar-content,.sidebar.collapsing .sidebar-filter,.sidebar.collapsing .sidebar-header,.sidebar.collapsing .sidebar-submenu,.sidebar.is-collapsed .sidebar-content,.sidebar.is-collapsed .sidebar-filter,.sidebar.is-collapsed .sidebar-header,.sidebar.is-collapsed .sidebar-submenu, .sidebar.is-collapsed .card-header {
		opacity: 0;
		visibility: hidden
	}

	.sidebar.expanding {
		width: 300px
	}

	.sidebar.expanding .sidebar-content,.sidebar.expanding .sidebar-filter,.sidebar.expanding .sidebar-header,.sidebar.expanding .sidebar-submenu {
		opacity: 1;
		visibility: visible
	}

	.sidebar.collapsing+.classic-table-content,.sidebar.expanding+.classic-table-content {
		transition: width .2s cubic-bezier(.645,.045,.355,1)
	}
	.sidebar-wrapper {
		position: fixed;
		display: flex;
		flex-direction: column;
		width: 266px;
		height: calc(100vh - 80px);
		transition: left .2s cubic-bezier(.645,.045,.355,1);
		background: $white;
		border-radius: .375rem;
	}
	.sidebar-toggle {
		position: absolute;
		padding: .66666665rem;
		color: $warning;
		cursor: pointer;
		width: 22px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin-bottom: .1rem;
		z-index: 1;
		background: $white;
		transition: .2s ease-out;
		top: 50%;
		right: 0;
	}
	.right-0 {
		right: 0;
	}
	.right-22 {
		right: -22px;
	}

	.sidebar-content {
		flex-grow: 1;
		position: relative;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scroll: touch;
		box-shadow: none;
		border-radius: 0;
		padding: 0 10px;
	}
	.classic-table-content {
		grid-area: classic-table-content;
		overflow: hidden;
		min-height: calc(100vh - 80px);
	}

	.filter {
		display: flex;
		padding: 1rem 0;
		justify-content: center;
	}
	.filter > .sf-nav-item.active {
		color: #fff;
		background-color: $warning;
	}

	.filter >.sf-nav-item:not(:last-child) {
		border-right: none;
	}
	.filter >.sf-nav-item:first-child {
		border-radius: 4px 0 0 4px;
	}
	.filter >.sf-nav-item {
		padding: .35rem 1.68rem;
		color: $warning;
		font-size: .85em;
		font-weight: 600;
		text-transform: uppercase;
		border: 1px solid $warning;
		white-space: nowrap;
		transition: .2s ease-out;
		text-decoration: none;
	}

}