html {
	scroll-behavior: smooth;
}
.navbar-top {
    font-weight: 500 !important;
}
input::-ms-reveal,
      input::-ms-clear {
        display: none !important;
      }


/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    cursor: pointer;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover ::-webkit-scrollbar {
    background: #ccc;
  }
    ::-webkit-scrollbar-corner,
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

//   div:hover::-webkit-scrollbar, .table-responsive:hover::-webkit-scrollbar{
//       height:10px
//     }

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: $link_active_clr;
}
a,.link-primary, .btn-link {
    color: $link_active_clr;
}
a:hover {
    color: $link_active_clr;
}
.report-link svg path {
    fill: $gray-700;
}


.grid-stack {
	>.grid-stack-item {
		>.grid-stack-item-content {
			left: 0 !important;
			right: 0 !important;
		}
	}
}

.grid-stack>.grid-stack-item[data-gs-x="0"]>.grid-stack-item-content {
    right: 10px !important;
    padding-right: 0.19rem !important;
}
.grid-stack>.grid-stack-item[data-gs-x="6"]>.grid-stack-item-content {
    padding-left: 0.19rem !important;
}
.grid-stack-item[data-gs-width="12"] >.grid-stack-item-content, form .grid-stack>.grid-stack-item:nth-child(odd)[data-gs-width="12"]>.grid-stack-item-content {
    right: 0 !important;
}
.grid-stack>.grid-stack-item>.grid-stack-item-content:not(.grid-stack>.enable_scroll>.grid-stack-item-content ){
    overflow-y:hidden !important;
}
svg.icons {
	width: 10px;
	height: 10px;
}


.col-2 {
    .navbar-vertical {
        .navbar-collapse {
            .navbar-vertical-content {
                height: $content_height;
            }
            .sf-view-list-height {
                height: calc(100vh - 13rem);
            }
        }
    }
}
.col-w-0 {
    width: $w-0 !important;
}

.dropdown-toggle:after { content: none }

.moduleOptionsDropdown .dropdown-menu a
{
    font-size: 0.8rem !important;
}

.sidebar-filter-options {
    position: absolute;
    left: 0;
    bottom: 0;
    background: $white;
    padding: 1rem;
    text-align: center;
    z-index: 9;
}

.list-group .list-group-item label {
    margin: 0;
}
.sticky-100 {
    top: 100px;
}
label.form-control{
    color: $gray-700;
    font-weight: 400 !important;
}
/*--------------Checkbox---------------------*/

.custom-control.custom-checkbox .custom-control-label{
    cursor: pointer;
}

/*-------------------------------------------*/
.required-icon {
    color: $danger;
}
.d-align-c {
    display: flex;
    align-items: center;
}

.dashboard-strip-connect {
    .profile {
        .photo {
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                box-shadow: 0px 1px 3px 1px #ddd;
            }
        }
    }
}
.fileinput-exists {
    overflow: hidden;
    margin-top: 2px !important;
}
select.list-group {
    padding: 0;
}

.btn-eclipse_w15 {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.navbar-vertical.navbar-expand-xl .navbar-collapse {
    margin-top: 0 !important;
}
.pe-none {
    pointer-events: none !important;
}
li.nav-item.pe-none {
    pointer-events: unset !important;
    cursor: not-allowed;
}
#theme-detail-view {
    .table-responsive {
        overflow-y: hidden;
        padding-bottom: 10px;
    }
}
#view_list {
    .content.pb-5 {
        padding-bottom: 0 !important;
    }
    #sfListViewColWrapper {
        .card {
            padding-bottom: 0 !important;
            height: calc(100vh - 12.5rem);
        }

        .table-responsive{
            max-height: calc(100vh - 21.1rem);
        }
    }
}
.paginationlinks {
    ul.pagination {
        margin: 0;
    }
}

span#push_left:not(span[onclick]), span#up:not(span[onclick]), span#down:not(span[onclick]), span#push_right:not(span[onclick]) {
    color: $dark !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $gray-700 !important;
  -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.nav-item.category:hover > .delete-category.pointer {
  display: inherit !important;
}
.overflow-x-auto{
    overflow-x: auto;
}
.overflow-x-hidden{
    overflow-x: hidden;
}
.overflow-y-auto{
    overflow-y: auto;
}
.overflow-y-hidden{
    overflow-y: hidden;
}

// .dropdown-menu:not(.dropdown-menu-right) {
//     left: unset !important;
//     transform: unset !important;
//     right: 100% !important;
// }
.c_card {
    -webkit-box-shadow: 0 0px 5px 0 #ddd;
    box-shadow: 0 0px 5px 0 #ddd;
}
.border_1 {
    border: 1px solid #eee;
}
.required_star {
    color: red;
}

#report-table {
    & > thead > tr > th {
        min-width: 300px;
        text-align: left;
    } 
}
.d-flex.d-none{ 
    display: none !important;
  }
.card.card-notification {
    min-width: 20rem;
}
.note-toolbar .note-btn, .note-editor.note-frame .note-editing-area .note-editable, .note-editor.note-airframe .note-editing-area .note-editable {
    background: inherit !important;
    color: inherit !important;
}
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-reset:hover, .note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-select:hover, .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-reset:hover, .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-select:hover {
    background: inherit !important;
}

.multiselect__tags {
    background: inherit !important;
}
.multiselect__content-wrapper {
    background-color: $white !important;
    color: $gray-700 !important;
}
.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
    background-color: $white !important;
}
.td-3 span {
    height: 12px;
    background: linear-gradient(to right, $gray-300 20%, $gray-100 50%, $gray-300 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}
#emailsCardBody {
    .td-3 span {
        height: 15px;
    }
}
.alert {
    p {
        margin: 0;
    }
}
#create-lookup-modal-content {
    .row {
        margin: 0;
        .card {
            box-shadow: none;
        }
    }
}
#lookup-modal-body {
    td:first-child, th:first-child {
        width: 50px !important;
        min-width: 50px !important;
    }
    td a {
        text-align: left;
    }
}
.overflow-visible {
    overflow: visible !important;
}

#stripe-card-number, #stripe-card-expiry, #stripe-card-cvc {
    border: 1px solid $feild_border;
    border-radius: 4px;
    margin-bottom: 20px;
    padding-top: 8px;
    padding-left: 10px;
    height: calc(1.5em + 0.625rem + 2px)!important;
  }


.select2:not(.select2-hidden-accessible) {
    border: 1px solid $feild_border !important;
    height: calc(2.09375rem + 3px) !important;
    background-color: $feild_border !important;
    width: 100%;
    border-radius: 4px;
}
.page-secondary-sidebar .card-header{
    padding: 1.25rem;
}
.item-active-dot {
    right: 0;
    top: 9px;
}
.popover {
    z-index: 1000;
}
.gap-1 {
    gap: 10px;
}
.header-h {
    height: 63px;
}
.webCheckoutLinkCopyToClipboard {
    background-color: $white; 
    color: $gray-700; 
    padding: 0.25rem 0.25rem; 
    margin-left: 0 !important; 
    line-height: 1; 
    display: inline-flex; 
    align-items: center; 
    font-size: 14px;
}

/* Base Skeleton Loader Styles */
.skeleton {
    background-color: $gray-100;
    border-radius: 4px;
    margin-bottom: 10px;
    width: 100%;
    height: 30px; /* Adjust the height as needed */
    position: relative;
    overflow: hidden; /* Ensure the shimmer stays within bounds */
}

/* Shimmer Effect */
.skeleton::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to right,
        $gray-200 0%,
        $gray-200 50%,
        $gray-200 100%
    );
    background-size: 200% 100%; /* Make the gradient twice the width of the element */
    animation: shimmer 1.5s infinite;
}

/* Shimmer Animation */
@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

.process_payment_modal {
    .link-btn {
        display: block;
        padding: 4px 8px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        &:hover {
            background: $gray-100;
        }
    }
}
.add-plan-card {
    .product-row td {
        padding: .5rem;
    }
    /* Table Layout */
    .table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
    }

    .table th, 
    .table td {
        padding: 15px 10px !important;
        text-align: left;
        vertical-align: middle;
        font-size: 14px;
        border-bottom: 1px solid var($gray-300) //e0e0e0
    }

    .table thead th {
        background-color: transparent;
        color: var($gray-700);
        /* text-transform: uppercase; */
        /* letter-spacing: 0.05em; */
        /* font-weight: 700; */
        border-bottom: 2px solid var($gray-300); //e0e0e0
    }
    .plans-table.table-bordered {
        box-shadow: 0px 3px 5px 0px var($gray-300); //ebebeb
    }

    .table tbody tr:hover {
        background-color: var($white); //fbfbfb
        transition: background-color 0.3s ease;
    }
    .table-bordered thead td, .table-bordered thead th {
        border-bottom-width: 1px;
    }

    

    /* Remove Icon Button */
    .remove-icon {
        background-color: #ff6b6b;
        color: var($white);
        padding: 8px;
        border-radius: 50%;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
    }

    .remove-icon:hover {
        background-color: #ff5252;
        transform: scale(1.1);
    }

    /* Collapse Section */
    .collapse-section {
        background-color: $white; //f9f9f9
        /* border-radius: 15px; */
        padding: 20px;
        margin-top: 15px;
        display: none;
        // border-left: 5px solid #4a90e2;
        animation: fadeInSlide 0.4s ease forwards;
    }

    @keyframes fadeInSlide {
        from { opacity: 0; transform: translateY(-10px); }
        to { opacity: 1; transform: translateY(0); }
    }

    /* Pricing Section */
    .pricing-item-row {
        background: $white;
        /* border-radius: 12px; */
        padding: 15px;
        box-shadow: 0 5px 15px var($gray-100); //black half
        margin-bottom: 15px;
    }
    .calculate-amount {
        max-width: 90px !important;
    }
    .table-striped tbody tr.pricing-item-row {
        background-color: $white;
    }
    .sticky-60 {
        top: 62px !important;
        z-index: 9;
    }
    .empty-space:last-child, .empty-space:first-child {
        td {
            display: none;
        }
    }
    .empty-space:not(:last-child):not(:first-child) {
        height: 20px;
        // outline: dashed;
        .separator {
            text-align: center;
        }
        
        .separator::before {
            content: '';
            display: block;
            width: 80%;
            margin: 0 auto;
            height: 1px;
            background-color: $gray-300;
            border-radius: 2px;
        }
        
        .separator:hover::before {
            background-color: $gray-700; /* Slightly darker on hover for interactivity */
        }
        
    }
    .input-group-append span {
        font-size: 14px;
    }
    input {
        font-size: 14px;
    }
    .w-mx-180 {
        input {
            max-width: 180px !important;
        }
    }
    .table-striped tbody tr.product-row , .table-striped tbody tr.pricing-row {
        background-color: $gray-100 !important;
    }
    .qty-span {
        color: $gray-700 !important;
        background-color: $gray-200 !important;
    }
    .pricing-row:not(:last-child){
        border-bottom: 2px solid $gray-200 !important;
    }
    
}


.navbar-customization-modal {
    /* Hover effect for list items */
    .list-group-item {
        transition: background-color 0.3s ease, transform 0.2s ease;
    }
    .sortable-selected {
        background-color: $yellow; /* Highlight selected item */
        border: 1px solid $gray-200;
        label {
            color: #fff;
        }
        color: #fff;
    }

    .list-group-item:hover {
        background-color: $yellow; /* Light grey background on hover */
        color: #fff;
        transform: scale(1.02); /* Slight zoom effect */
        label {
            color: #fff;
        }
    }

    /* Adding hover effect to the sort icon */
    .fas.fa-bars {
        transition: color 0.3s ease, transform 0.2s ease;
    }

    .fas.fa-bars:hover {
        color: #007bff; /* Change icon color on hover */
        transform: rotate(90deg); /* Rotate icon on hover */
    }

    /* Animation when dragging (Sortable.js will use this class) */
    .sortable-ghost {
        opacity: 0.5; /* Make the item semi-transparent while dragging */
        transform: scale(1.05); /* Slight zoom effect while dragging */
    }

    /* Add animation on sorting */
    .list-group-item {
        animation: fadeInUp 0.3s ease-in-out;
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.content-h-100 {
    height: calc(100vh - 180px);
    overflow: auto;
}
.classic-table-left {
    width: 300px;
    .fixed-position {
        position: fixed;
        // top: 87px;
        height: calc(100vh - 80px);
        width: 300px;
        overflow: auto;
    }
}

hr + hr {
    display: none;
}
.page-link {
    min-width: 35px;
    text-align: center;
    &:hover {
        background-color: $gray-500;
        border-color: $gray-500;
    }
}
.table-auto {
    table-layout: auto;
}

.modal-body:has(#payment-method-form) {
    padding-bottom: 0 !important;
}

.modal-title {
    font-size: 24px;
    font-weight: 600;
    color: $gray-800;
}
.col-12.credit-card-fields label {
    width: 100%;
}
.dropdown-menu.max-height {
    max-height: 300px;
    overflow-y: auto;
  }
  .dropdown-menu.max-height-5 {
    max-height: 500px;
    overflow-y: auto;
  }

.chargeable-immediately-wrapper {
    background-color: #ffe9e8 !important;
    p, h4 {
        color: #5c2d14;
    }
    i, svg {
        color:#e94e00;
    }
}
